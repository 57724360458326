import React, { Fragment, useEffect } from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import styled from "@emotion/styled"

import {
  SectionContainer,
  SectionInnerWrapper,
  RowWrapper,
} from "../../components/Layout/styled"

import Footer from "../../components/Layout/Footer"
import SeoComp from "../../components/SeoComp"
import { isBrowser } from "../../context/ApolloContext"

const $ = isBrowser ? require("jquery") : {}

const ConditionsGeneralesDeVente = ({ intl, data }) => {
  const handleHashScrolling = () => {
    $(document).ready(function() {
      $("html, body").hide()
      if (
        window.location.hash === "#discount_offer" ||
        window.location.hash === "#newsletter_offer"
      ) {
        setTimeout(function() {
          $("html, body")
            .scrollTop(0)
            .show()
          $("html, body").animate(
            {
              scrollTop: $(window.location.hash).offset().top - 100,
            },
            1500
          )
        }, 0)
      } else {
        $("html, body").show()
      }
    })
  }

  useEffect(() => {
    isBrowser && handleHashScrolling()
  }, [intl.locale])
  return (
    data &&
    data.allDirectusGeneralTermPageTranslation.nodes.length > 0 && (
      <Fragment>
        <SeoComp
          description={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].meta_description
          }
          title={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].meta_title
          }
          twitter_card={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].twitter_card
          }
          image={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].image &&
            data.allDirectusSeoTranslation.nodes[0].image.data &&
            data.allDirectusSeoTranslation.nodes[0].image.data.url
          }
          hreflangPathname={{
            fr: "conditions-generales-de-vente",
            en: "terms-of-sale",
            de: "terms-of-sale",
            es: "terms-of-sale",
          }}
        />
        <SectionContainer>
          <SectionInnerWrapper align="flex-start" justify="flex-start">
            {data.allDirectusGeneralTermPageTranslation.nodes[0].title && (
              <Title
                dangerouslySetInnerHTML={{
                  __html:
                    data.allDirectusGeneralTermPageTranslation.nodes[0].title,
                }}
              />
            )}
            {data.allDirectusGeneralTermPageTranslation.nodes[0].subtitle && (
              <Subtitle
                dangerouslySetInnerHTML={{
                  __html:
                    data.allDirectusGeneralTermPageTranslation.nodes[0]
                      .subtitle,
                }}
              />
            )}

            {process.env &&
            data.allDirectusGeneralTermPageTranslation.nodes[0].content &&
            process.env.GATSBY_ACTIVE_ENV === "gbp" ? (
              <TextBox
                dangerouslySetInnerHTML={{
                  __html:
                    data.allDirectusGeneralTermPageTranslation.nodes[0]
                      .gbp_content,
                }}
              />
            ) : process.env.GATSBY_ACTIVE_ENV === "eur" &&
              intl.locale === "fr" ? (
              <TextBox
                dangerouslySetInnerHTML={{
                  __html:
                    data.allDirectusGeneralTermPageTranslation.nodes[0]
                      .eur_content,
                }}
              />
            ) : (
              <TextBox
                dangerouslySetInnerHTML={{
                  __html:
                    data.allDirectusGeneralTermPageTranslation.nodes[0].content,
                }}
              />
            )}
            {/* {data.allDirectusGeneralTermPageTranslation.nodes[0].content && (
            <TextBox
              dangerouslySetInnerHTML={{
                __html:
                  data.allDirectusGeneralTermPageTranslation.nodes[0].content,
              }}
            />
          )} */}
          </SectionInnerWrapper>
        </SectionContainer>

        {data.allDirectusFooterTranslation.nodes.length > 0 && (
          <Footer data={data.allDirectusFooterTranslation.nodes[0]} />
        )}
      </Fragment>
    )
  )
}

const Title = styled.h1`
  color: #262626;
  font-size: 28px;
  line-height: 1.05;
  letter-spacing: 0.05em;
  font-family: "BebasNeueBold";
  font-weight: bold;
`

const Subtitle = styled.h2`
  color: #555;
  font-size: 22px;
  line-height: 24px;
  letter-spacing: 0.05em;
  font-family: "Museo";
  font-weight: 500;
  margin-bottom: 0.5em;
`

const TextBox = styled.div`
  color: #777;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  letter-spacing: 0;

  h2,
  h3 {
    color: #777;
    font-size: 14px;
    line-height: 24px;
    font-family: "GothamLight";
    width: 100%;
    letter-spacing: 0;
    margin: 0px;
  }

  > p {
    margin-bottom: 1.3em;
  }
`

export const query = graphql`
  query($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDirectusGeneralTermPageTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        title
        subtitle
        content
        eur_content
        gbp_content
      }
    }
    allDirectusFooterTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        facebook
        gbp_facebook
        youtube
        twitter
        instagram
        linkedin
        about_us {
          name
          list {
            link
            list
          }
        }
        contact {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        purchases {
          list {
            list
            link
          }
          name
        }
        help {
          list {
            link
            list
          }
          name
        }
        about_us_menu {
          name
          list {
            link
            list
          }
        }
        knowledge_center_menu {
          name
          list {
            link
            list
          }
        }
        shop_menu {
          name
          list {
            link
            list
          }
        }
        support_menu {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        newsletter_heading
        follow_us_heading
        footer_bottom {
          list {
            link
            list
          }
        }
      }
    }
    allDirectusSeoTranslation(
      filter: {
        language: { eq: $lang }
        seo: { page_name: { eq: "conditions_generales_de_vente" } }
      }
    ) {
      nodes {
        meta_title
        meta_description
        twitter_card
        twitter_title
        url
        image {
          data {
            url
          }
        }
      }
    }
  }
`

export default injectIntl(ConditionsGeneralesDeVente)
